import React from "react"
import ServiceSidebarArchitecture from "./ServiceSidebarArchitecture"
import details1 from "../../assets/images/services/banner-security.jpg"
import { Link } from "gatsby"

const SecurityDesignContent = ({ location }) => {
  return (
    <section className="services-details-area ptb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="services-details-image">
              <img src={details1} alt="about" />
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <ServiceSidebarArchitecture location={location} />
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="services-details-desc">
              <span className="sub-title">SOFTWARE ARCHITECTURE DESIGN</span>

              <h3>Security Architecture</h3>
              <h5>
                Central IDM System build, Security Role management & check for
                modules and entire systems. Transport and Data Encryption for
                persistence in all solution layers like Frontend and Backend
                modules.
              </h5>

              <p>
                Every solution that is accessible from the internet can be an
                attack vector for potential threats that can lead to losses of
                millions of $ or €, either in legal or brand damages. External
                and internal security threats become more prominent every day.
                Hackers perfect their tools in an ongoing arms race between
                black hats (criminals) and white hats (the good security guys).{" "}
                <br />
                <b>
                  Nobody wants to find out about security leakage in their
                  company reading press news.
                </b>
                <br />
                Resulting impact on brand image can be disastrous.
              </p>
              <p>
                Arch Forge Solutions has extensive experience in building secure
                systems in financial services & medical space. We created
                applications for the banking sector, and secured medical systems
                for hospitals and insurance companies. Processing personal
                financial data or confidential medical records for patients is
                always security sensitive. If needed we can build a HIPPA or PCI
                compliant system for you.
              </p>

              <div className="row align-items-center">
                <div className="col-lg-12 col-md-12">
                  <div className="content">
                    <h3>
                      Here's what can we do to help build a secure solution:
                    </h3>
                    <ul>
                      <li>
                        Analyse existing software solutions and prepare threat
                        model for potential attack vectors
                      </li>
                      <li>
                        Perform a backend code audit for security threats and
                        vulnerabilities
                      </li>
                      <li>
                        Analyse software dependencies in search of library
                        security issues
                      </li>
                      <li>
                        Prepare a security design for your solution with proper
                        authentication, authorisation and role/permission
                        concept
                      </li>
                      <li>
                        Design a proper interface security strategy so that data
                        sent in internal and external connections is safe
                      </li>
                      <li>
                        Integrate an application like backend or frontend with
                        IAM/SSO solution
                      </li>
                      <li>
                        Create a custom IAM/SSO solution for your landscape with
                        custom processes and login/registration steps
                      </li>
                      <li>
                        Add security hardening for your mobile app, which is
                        crucial in financial services frontends
                      </li>
                      <li>
                        Introduce security auditing for potential security
                        incident forensics
                      </li>
                      <li>
                        Build resilient databases with encryption at rest and
                        secure communication
                      </li>
                      <li>
                        Build secure API Gateway for external API exposed in
                        Internet
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <p>
                For more generic security audits on infrastructure please take a
                look at our services in security hardening area{" "}
                <Link to="/services-security">
                  <b>->HERE</b>
                </Link>{" "}
                or contact us directly.
              </p>

              <h3 className="pt-30">Technology</h3>
              <p>
                Technologies & tools that we typically use in our security
                solutions.
              </p>
              <ul className="technologies-features">
                <li>
                  <span>AWS Cognito IAM</span>
                </li>
                <li>
                  <span>Azure AD IAM</span>
                </li>
                <li>
                  <span>Google IAM</span>
                </li>
                <li>
                  <span>KeyCloak</span>
                </li>
                <li>
                  <span>LUKS FDE</span>
                </li>
                <li>
                  <span>Hashicorp Vault</span>
                </li>
                <li>
                  <span>Wireshark</span>
                </li>
                <li>
                  <span>Snyk</span>
                </li>
                <li>
                  <span>SonarQube</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SecurityDesignContent
